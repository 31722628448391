import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import CommonAnimatedLoader from '../../components/common/animated/loader';
import * as ordersService from '../../services/api/orders';
import { useAuth } from '../../hooks/use-auth';
import { Order } from '../../entities/order';
import CommonAnimatedSpinner from '../../components/common/animated/spinner';
import CommonButtonDefault from '../../components/common/buttons/default';
import { getPatient } from '../../services/api/patients';
import PatientProps from '../../entities/patient';
import QuestionnaireAnswerComponent from '../../components/order/questionnaire-answer';
import { MessagesContextProvider } from '../../context/messages';
import OrdersResultsQuestionnaireMessagesComponent from '../orders/results-questionnaire-messages';
import { OrderView } from '../../entities/order-view';
import AlertPrimary from '../../components/common/alerts/alert-primary';
import OrdersTableComponent from '../../components/orders/table';
import { treatmentView } from '../../services/api/treatment';
import TreatmentStatusBadge from '../../components/patients/treatment-status-badge';
import TreatmentViewProps from '../../entities/treatment-view';

function PatientsPreviewPageComponent() {
  const { hash } = useParams();
  const { token, user } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const [patient, setPatient] = useState<PatientProps>();
  const [treatment, setTreatment] = useState<TreatmentViewProps>();
  const [order, setOrder] = useState<Order>();
  const [currOrder, setCurrOrder] = useState<OrderView>();
  const [orders, setOrders] = useState<Order[]>();

  useEffect(() => {
    const fetchData = async () => {
      if (hash) {
        try {
          setIsLoading(true);
          const res = await getPatient(token, hash);

          setPatient(res.patient);
          setOrders(res.orders);

          if (res.orders.length > 0) {
            setOrder(res.orders[0]);
          }

          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          setError((e as Error).message);
        }
      }
    };

    fetchData();
  }, [token, hash]);

  useEffect(() => {
    const fetchData = async (id: number) => {
      setIsOrderLoading(true);
      const item = await ordersService.view(token, id);

      setCurrOrder(item);
      setIsOrderLoading(false);
    };

    if (order?.id) {
      fetchData(order?.id);
    }
  }, [token, order]);

  useEffect(() => {
    const fetchData = async (treatmentHash: string) => {
      const item = await treatmentView(token, treatmentHash);

      setTreatment(item);
    };

    if (patient?.treatmentHash) {
      fetchData(patient.treatmentHash);
    }
  }, [token, patient?.treatmentHash]);

  const setPageNo = (currentPage: number) => {
    return 0;
  };

  return (
    <div>
      {isLoading && <CommonAnimatedLoader />}
      {error && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-5">
          <AlertPrimary text={error} />
        </div>
      )}
      {patient && (
        <div>
          <div className="py-5 relative bg-white border-b border-gray-200 px-4 sm:px-6 lg:px-8">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Patient: {patient.name} {patient.lastName}
            </h3>
          </div>

          <div className="xl:flex xl:space-x-3 mb-5 pb-5 full-width px-4 sm:px-6 lg:px-8 pt-5">
            <div className="w-full xl:w-3/5">
              <div className="pb-5">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg px-4 sm:px-6 py-5 text-sm">
                  <div className="md:flex md:space-x-3">
                    <div className="md:w-1/3 space-y-1">
                      <div>
                        <b>Age:</b> {patient.age ? patient.age : '-'}
                      </div>
                      {patient.personCode && (
                        <div>
                          <b>Personal Code:</b> {patient.personCode}
                        </div>
                      )}
                      {patient.isChild && (
                        <div>
                          <b>Parent:</b> {patient.customerName} {patient.customerLastName}
                        </div>
                      )}
                    </div>
                    <div className="space-y-1 mt-1 md:mt-0">
                      <div>
                        <b>Phone:</b> {orders && orders?.length > 0 ? orders[0].phone : patient.phone}
                      </div>
                      {patient.email && (
                        <div>
                          <b>Email:</b> {patient.email}
                        </div>
                      )}

                      <div>
                        <b>Address:</b> {patient.customerAddress}
                        {patient.customerCity && `, ${patient.customerCity}`}
                        {patient.customerZipCode && `, ${patient.customerZipCode}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                {orders && orders?.length > 0 && (
                  <div className="pb-5 relative">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Orders</h3>
                    <OrdersTableComponent orders={orders} pages={0} onUpdate={setPageNo} currPageNo={1} />
                  </div>
                )}
                {!user?.isClinicUser && (
                  <div className="py-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Treatment</h3>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg px-4 sm:px-6 py-5 mt-4">
                      {!treatment ? (
                        <Link to={`/treatment/create/${patient.id}/${patient.hash}`}>
                          <CommonButtonDefault primary>Add treatment</CommonButtonDefault>
                        </Link>
                      ) : (
                        <div>
                          <div className="md:flex md:space-x-4">
                            <div className="space-y-4 md:w-1/3">
                              <div>
                                <b>Allergen:</b>
                                {treatment.sources && (
                                  <div>
                                    {treatment.sources.map((source, index) => (
                                      <div key={`source-${source.id}-${index + 1}`}>{source.title}</div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              <div>
                                <b>Symptoms:</b>
                                {treatment.symptoms && (
                                  <div>
                                    {treatment.symptoms.map((symptom, index) => (
                                      <div key={`symptom-${symptom.id}-${index + 1}`}>{symptom.title}</div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="space-y-4 md:w-1/3">
                              <div>
                                <b>Drugs for Symptoms:</b>
                                {treatment.drugs.length > 0 ? (
                                  <div>
                                    {treatment.drugs.map((drug, index) => (
                                      <div key={`drug-${drug.drugId}-${index + 1}`}>
                                        {drug.title} - {drug.quantity}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <> -</>
                                )}
                              </div>
                              <div>
                                <b>Time:</b> {treatment.treatment.time}
                              </div>
                            </div>
                            <div className="space-y-4 md:w-1/3">
                              <div className="space-y-2">
                                {/* <b>Id:</b> {treatment.treatment.id} */}
                                <div>
                                  <b>Status:</b> <TreatmentStatusBadge status={treatment.treatment.status} />
                                </div>
                                <div>
                                  <b>Reminders:</b>{' '}
                                  {treatment.treatment.reminders ? (
                                    <span className="inline-flex items-center rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                      on
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center rounded bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                                      off
                                    </span>
                                  )}
                                </div>
                                <div className="">
                                  <b>Benchmark score:</b>{' '}
                                  {treatment.treatment.benchmarkScore ? treatment.treatment.benchmarkScore : '-'}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to={`/treatment/edit/${patient.id}/${patient.hash}`}>
                            <CommonButtonDefault className="mt-5" primary>
                              Edit treatment
                            </CommonButtonDefault>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {isOrderLoading && (
                  <div className="relative py-5 flex items-center justify-center">
                    <CommonAnimatedSpinner />
                  </div>
                )}
                {currOrder?.questionnaires && (
                  <div>
                    <div className="py-5">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Symptoms</h3>
                    </div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      {currOrder.questionnaires && (
                        <QuestionnaireAnswerComponent questionnaires={currOrder.questionnaires} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full xl:w-2/5 mt-5 xl:mt-0">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                {!user?.isClinicUser && (
                  <div>
                    <div className="px-4 py-5 sm:px-6">Create follow-up questions</div>

                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      {currOrder?.order.customerId ? (
                        <MessagesContextProvider customerId={currOrder.order.customerId}>
                          <OrdersResultsQuestionnaireMessagesComponent
                            customerId={currOrder.order.customerId}
                            treatmentPlan3Url={currOrder.treatmentPlan3Url}
                            treatmentPlan6Url={currOrder.treatmentPlan6Url}
                          />
                        </MessagesContextProvider>
                      ) : (
                        <div>The customer is not registered</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PatientsPreviewPageComponent;
